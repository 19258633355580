import React, { useState, useEffect } from 'react'
import SummaryBody from './SummaryBody'
import client from '../../../../client'

function SummaryTab(props) {
  const initialState = {
    summaries: [],
  }

  const [state, setState] = useState({ ...initialState })

  const tabClick = () => {
    const listener = () => {
      fetchSummary()
    }

    document.querySelector('#summary-tab').addEventListener('click', listener)

    return () => {
      document
        .querySelector('#summary-tab')
        .removeEventListener('click', listener)
    }
  }

  const fetchSummary = () => {
    const { requestId } = props
    client
      .get(`/admin/v2/requests/${requestId}/summary`)
      .then((response) => response.json())
      .then((data) => {
        setState(() => ({
          summaries: data.summaries,
        }))
      })
      .catch((error) => console.error('Error fetching summaries:', error))
  }

  const refreshSummary = () => {
    const { requestId } = props
    client
      .post(`/admin/v2/requests/${requestId}/refresh_summary`)
      .then((response) => response.json())
      .then((data) => {
        fetchSummary()
      })
      .catch((error) => console.error('Error refreshing summary:', error))
  }

  const refreshInsights = () => {
    const { requestId } = props
    client
      .post(`/admin/v2/requests/${requestId}/refresh_insights`)
      .then((response) => response.json())
      .then((data) => {
        fetchSummary()
      })
      .catch((error) => console.error('Error refreshing the insights:', error))
  }

  useEffect(() => {
    tabClick()
  }, [])

  return (
    <>
      <div className="text-end">
        <button
          className="btn btn-secondary request-modal-button m-2"
          onClick={refreshSummary}
        >
          <i className="fas fa-sync" style={{ color: 'white' }}></i> Refresh
          Summary
        </button>
        <button
          className="btn btn-secondary request-modal-button m-2"
          onClick={refreshInsights}
        >
          <i className="fas fa-sync" style={{ color: 'white' }}></i> Refresh
          Case Insights
        </button>
      </div>
      <SummaryBody data={state.summaries} />
    </>
  )
}

export default SummaryTab
