import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Markdown from 'react-markdown'
import { Global, css } from '@emotion/react'
import remarkGfm from 'remark-gfm'
import MermaidChart from './MermaidChart'

const markdownCSS = css`
  .summary-markdown {
    h1 {
      font-size: 1.75em;
    }
    h2 {
      font-size: 1.5em;
    }
    h3 {
      font-size: 1.25em;
    }
    h4 {
      font-size: 1em;
    }
    h5 {
      font-size: 0.75em;
    }
  }
`

const SummaryBody = ({ data }) => {
  useEffect(() => {
    if (window.mermaid) {
      window.mermaid.initialize({ startOnLoad: true })
    }
  }, [])

  return (
    <>
      <Global styles={markdownCSS} />
      {data.map((item, index) => (
        <div key={index} className="summary__row">
          <div className="summaries-header">
            <span className="form_category__title">Summary</span>
          </div>
          <br />
          <div className="summary-markdown">
            <Markdown remarkPlugins={[remarkGfm]}>{item.summary}</Markdown>
          </div>
          <hr />
          <div className="summaries-header">
            <span className="form_category__title">Timeline</span>
          </div>
          <div className="summary-chart">
            {item.timeline && <MermaidChart chart={item.timeline} />}
          </div>
          <hr />
        </div>
      ))}
    </>
  )
}

SummaryBody.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      summary_type: PropTypes.string.isRequired,
      summary: PropTypes.string.isRequired,
      timeline: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

SummaryBody.defaultProps = {
  data: [],
}

export default SummaryBody
